var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "q-header",
        { staticClass: "text-primary ColoreBarra", attrs: { elevated: "" } },
        [
          _c(
            "q-toolbar",
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isNatale,
                    expression: "isNatale",
                  },
                ],
                staticStyle: { "max-width": "50px", "max-height": "50px" },
                attrs: { src: require("@/images/albero-natale.png") },
              }),
              _c(
                "div",
                {
                  staticClass: "NomePiattaforma",
                  staticStyle: { "padding-left": "16px" },
                },
                [_vm._v(" ABY"), _c("i", [_vm._v("Next ")])]
              ),
              _c("q-space"),
              _c("q-btn-toggle", {
                attrs: {
                  "toggle-color": "red-9",
                  color: "grey",
                  options: [
                    { label: "Light", value: "Light" },
                    { label: "Dark", value: "Dark" },
                  ],
                },
                on: {
                  click: function ($event) {
                    return _vm.CambiaSfondoProgramma()
                  },
                },
                model: {
                  value: _vm.BrowserViewMode,
                  callback: function ($$v) {
                    _vm.BrowserViewMode = $$v
                  },
                  expression: "BrowserViewMode",
                },
              }),
              _c("q-btn", {
                attrs: {
                  color: "grey",
                  "text-color": "white",
                  label: "Esci",
                  icon: "mdi-power",
                },
                on: {
                  click: function ($event) {
                    return _vm.esciDalProgramma()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c(
        "div",
        {
          staticClass: "justify-center",
          staticStyle: { "margin-bottom": "-40px" },
          attrs: { align: "center" },
        },
        [
          _c("q-btn-toggle", {
            attrs: {
              "text-color": "white",
              "toggle-color": "red-9",
              color: "blue-grey",
              options: _vm.sezioni,
              spread: "",
            },
            on: {
              click: function ($event) {
                return _vm.cambiaSezione(_vm.sezione_visualizzata)
              },
            },
            model: {
              value: _vm.sezione_visualizzata,
              callback: function ($$v) {
                _vm.sezione_visualizzata = $$v
              },
              expression: "sezione_visualizzata",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }